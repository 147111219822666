.outletSection {
  background: #e83953;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outletsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
}

.heading {
  text-align: center;
  padding: 50px 0 30px 0;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 30vw;
}

.verticalLine {
  border-left: 6px solid white;
  height: 500px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.branch {
  font-size: large;
  padding: 5px;
}

.address {
  font-size: x-small;
  font-style: italic;
  padding-bottom: 5px;
  width: 300px;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  object-fit: contain;
  margin: 5px;
  padding: 0;
}

@media (max-width: 801px) {
  .outletSection {
    height: fit-content;
    display: block;
  }
  .outletsContainer {
    flex-direction: column;
    padding-bottom: 30px;
  }
  .heading {
    width: 100%;
  }
  .card {
    margin-bottom: 50px;
  }
}
