.footerContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-top: 50px;
  padding-bottom: 30px;
  background: #e83953;
}

.footerItem {
  cursor: pointer;
  color: #fad4d4;
}

.footerItem:hover {
  color: white;
}

@media (max-width: 801px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
  }
}
